import { Box } from "@mui/material";
import useApi from "./useApi";
import React from "react";
import ChatMessageMarkdown from "./ChatMessageMarkdown";
import ChatMessageLoading from "./ChatMessageLoading";
import ChatPrompt from "./ChatPrompt";
import ChatMessageVerification from "./ChatMessageVerification";
import useAnalytics from "./useAnalytics";

export default function Chat() {
  const analytics = useAnalytics();
  const [messages, setMessages] = React.useState<
    { role: "assistant" | "user"; content: string }[]
  >([]);
  const addMessage = React.useCallback(
    (role: "assistant" | "user", content: string) => {
      setMessages((messages) => [...messages, { role, content }]);
    },
    [setMessages]
  );
  const [verificationNeeded, setVerificationNeeded] = React.useState(false);
  const [pendingQueryText, setPendingQueryText] = React.useState<string | null>(
    null
  );
  const api = useApi();
  const submit = React.useCallback(
    (queryText: string, retry: boolean = false) => {
      if (api.loading) {
        return;
      }
      if (!retry) {
        addMessage("user", queryText);
      }
      api.get({
        path: "/query",
        params: { queryText },
        onSuccess: (response) => {
          const responseText: string =
            typeof response.responseText === "string"
              ? response.responseText
              : "";
          addMessage("assistant", responseText);
          setPendingQueryText(null);
          analytics.event("query_success");
        },
        onError: (error) => {
          if (error === "queryText is too long") {
            addMessage("assistant", "Your question is too long.");
            analytics.event("query_too_long");
            return;
          }
          if (error === "verification needed") {
            setVerificationNeeded(true);
            setPendingQueryText(queryText);
            analytics.event("verification_needed");
            return;
          }
          if (error === "query limit exceeded") {
            addMessage(
              "assistant",
              "I'm sorry, but it seems like you've reached the limit for inquiries. Unfortunately, we have restrictions in place to ensure that our service remains free for everyone to use. However, I invite you to return in approximately 30 minutes to pose your question again. Thank you for your understanding in this matter."
            );
            analytics.event("query_limit_exceeded");
            return;
          }
          if (error === "query flagged") {
            addMessage(
              "assistant",
              "Your message appears to violate ChatGPT's usage policies. Please refrain from using inappropriate messages."
            );
            analytics.event("query_flagged");
            return;
          }
          addMessage("assistant", "Unexpected error. Try again later.");
        },
      });
      analytics.event("query_submit");
    },
    [addMessage, api]
  );

  return (
    <Box>
      {messages.map((message, index) => (
        <ChatMessageMarkdown
          key={index}
          role={message.role}
          content={message.content}
        />
      ))}
      {verificationNeeded && (
        <ChatMessageVerification
          onSuccess={() => {
            setVerificationNeeded(false);
            if (pendingQueryText !== null) {
              submit(pendingQueryText, true);
            }
          }}
        />
      )}
      {api.loading && <ChatMessageLoading />}
      <ChatPrompt disabled={api.loading} onSubmit={submit} />
    </Box>
  );
}
